import React from 'react';
import './App.scss';
import MapFilter from './MapFilter';
import GMap from './GMap';
import MediaQuery from 'react-responsive';
import axios from 'axios';
import qs from 'qs';
import type { Filter, Immo } from './Types';

function MapWrapper(props) {
  const [locations: Immo[], setLocations] = React.useState([]);
  const [filteredLocations: Immo[], setFilteredLocations] = React.useState(
    locations
  );
  const [filter: Filter, dispatch] = React.useReducer(handleChange, {
    text: '',
    property_status: '',
    property_type: '',
    state: '',
    price_min: '',
    price_max: '',
    area_min: '',
    area_max: '',
    country: 'AUT',
    response: 'ID',
  });
  const [filterIsOpen: boolean, setFilterIsOpen] = React.useState(
    window.matchMedia('(min-device-width: 1224px)').matches
  );
  const [meta, setMeta] = React.useState({});

  function toggleFilter() {
    setFilterIsOpen(!filterIsOpen);
  }

  function handleChange(filter: Filter, event) {
    const value = event.target.value;
    const name = event.target.name;
    switch (name) {
      case 'query':
        return { ...filter, ...{ text: value } };
      case 'contract':
        return { ...filter, ...{ property_status: value } };
      case 'type':
        return { ...filter, ...{ property_type: value } };
      case 'land':
        return { ...filter, ...{ state: value } };
      case 'priceMin':
        return { ...filter, ...{ price_min: value } };
      case 'priceMax':
        return { ...filter, ...{ price_max: value } };
      case 'sqmMin':
        return { ...filter, ...{ area_min: value } };
      case 'sqmMax':
        return { ...filter, ...{ area_max: value } };
      default:
        break;
    }
  }

  React.useEffect(() => {
    filterLocations(locations, filter);
  }, [filter, locations]);

  function filterLocations(list, filter) {
    if (meta.base_url) {
      const filterExport: Filter = filter;
      delete filterExport.land;
      axios({
        method: 'post',
        url: meta.base_url + '/api/v1/search',
        data: qs.stringify(filterExport),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      }).then((res) => {
        const results = res.data.results;
        if (results) {
          list = list.filter((object: Immo) => {
            return results.indexOf(object.openimmo_idid) !== -1;
          });
          setFilteredLocations(list);
        } else {
          list = [];
          setFilteredLocations(list);
        }
      });
    }
  }

  return [
    <div id={'input-fields'}>
      <MapFilter
        mapFilterChanged={dispatch}
        locationNumber={filteredLocations.length}
        filter={filter}
        filterIsOpen={filterIsOpen}
        setFilterIsOpen={setFilterIsOpen}
        toggleFilter={toggleFilter}
      />
    </div>,
    <GMap
      settings={props.settings}
      setLocations={setLocations}
      locations={locations}
      setFilteredLocations={setFilteredLocations}
      filteredLocations={filteredLocations}
      meta={meta}
      setMeta={setMeta}
    />,
    <button className={'filter-button'} onClick={toggleFilter} type={'button'}>
      Filter{' '}
      <MediaQuery minDeviceWidth={1224}>
        {filterIsOpen ? 'ausblenden' : 'anzeigen'}
      </MediaQuery>
    </button>,
  ];
}

export default React.memo(MapWrapper);
