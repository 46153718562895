import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import * as serviceWorker from './serviceWorker';
import MapWrapper from './MapWrapper';

const target = document.getElementById('erw-root');
if (target) {
  const id = target.dataset.id;
  const settings = window.erwSettings[id];
  ReactDOM.render(
    <React.StrictMode>
      <MapWrapper settings={settings} />
    </React.StrictMode>,
    target
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
