import React from 'react';
import './App.scss';
import MediaQuery from 'react-responsive';
import Modal from 'react-modal';
import _ from 'lodash';

function MapFilter(props) {
  let input1;
  let input2;
  let input3;
  let input4;
  let input5;
  let select1;
  let select2;
  let select3;
  let modalCloseButton;

  Modal.setAppElement('#erw-root');
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0 0 0 3px #EEC45A',
      width: '75vw',
      height: '50vh',
      overflow: 'scroll',
      borderRadius: 0,
    },
  };

  function afterOpenModal() {
    let modalInputs = [
      input1,
      input2,
      input3,
      input4,
      input5,
      select1,
      select2,
      select3,
    ];
    modalInputs.forEach((name) => {
      name.style.margin = '4px';
      name.style.width = '100%';
      name.style.backgroundColor = 'white';
      name.style.borderRadius = '5px;';
    });
    modalCloseButton.style.position = 'absolute';
    modalCloseButton.style.top = 0;
    modalCloseButton.style.right = 0;
    modalCloseButton.style.color = '#eec45a';
    modalCloseButton.style.backgroundColor = 'white';
    modalCloseButton.style.height = '42px';
    modalCloseButton.style.width = '42px';
    modalCloseButton.style.padding = 0;
    modalCloseButton.style.fontSize = '24px';
  }

  const debounceLoadData = React.useCallback(
    _.debounce(props.mapFilterChanged, 300),
    []
  );

  function handleFilterChange(event) {
    event.persist();
    debounceLoadData(event);
  }

  function handleSelects(event) {
    event.persist();
    props.mapFilterChanged(event);
  }

  function formContent() {
    return [
      <form>
        <div className={'input-row'}>
          <label>
            Freitext
            <br />
            <input
              ref={(_input) => (input1 = _input)}
              type={'text'}
              placeholder={'Suche'}
              name={'query'}
              value={props.filter.query}
              onChange={handleFilterChange}
            />
          </label>
          <br />
          <label>
            Objekt zum
            <br />
            <select
              ref={(_input) => (select1 = _input)}
              value={props.filter.contract}
              name={'contract'}
              onChange={handleSelects}
            >
              <option selected value={''}>
                Auswählen...
              </option>
              <option value="buy">Kaufen</option>
              <option value="rent">Mieten</option>
            </select>
          </label>
          <br />
          <label>
            Immobilienart
            <br />
            <select
              ref={(_input) => (select2 = _input)}
              value={props.filter.type}
              name={'type'}
              onChange={handleSelects}
            >
              <option selected value={''}>
                Auswählen...
              </option>
              <option value="Wohnung">Wohnung</option>
              <option value="Haus">Haus</option>
            </select>
          </label>
          <br />
          <label>
            Bundesland
            <br />
            <select
              ref={(_input) => (select3 = _input)}
              value={props.filter.land}
              name={'land'}
              onChange={handleSelects}
            >
              <option selected value={''}>
                Auswählen...
              </option>
              <option value="Steiermark">Steiermark</option>
              <option value="Kärnten">Kärnten</option>
              <option value="Burgenland">Burgenland</option>
              <option value="Tirol">Tirol</option>
              <option value="Vorarlberg">Vorarlberg</option>
              <option value="Salzburg">Salzburg</option>
              <option value="Oberösterreich">Oberösterreich</option>
              <option value="Niederösterreich">Niederösterreich</option>
              <option value="Wien">Wien</option>
            </select>
          </label>
          <br />
        </div>
        <div className={'input-row'}>
          <label>
            Preis von
            <br />
            <input
              ref={(_input) => (input2 = _input)}
              type={'number'}
              placeholder={'von €'}
              min={0}
              max={Number.MAX_VALUE}
              name={'priceMin'}
              value={props.filter.priceMin}
              onChange={handleFilterChange}
            />
          </label>
          <br />
          <label>
            Preis bis
            <br />
            <input
              ref={(_input) => (input3 = _input)}
              type={'number'}
              placeholder={'bis €'}
              min={0}
              max={Number.MAX_VALUE}
              name={'priceMax'}
              value={props.filter.priceMax}
              onChange={handleFilterChange}
            />
          </label>
          <br />
          <label>
            Freifläche von
            <br />
            <input
              ref={(_input) => (input4 = _input)}
              type={'number'}
              placeholder={'von ㎡'}
              min={0}
              max={Number.MAX_VALUE}
              name={'sqmMin'}
              value={props.filter.sqmMin}
              onChange={handleFilterChange}
            />
          </label>
          <br />
          <label>
            Freifläche bis
            <br />
            <input
              ref={(_input) => (input5 = _input)}
              type={'number'}
              placeholder={'bis ㎡'}
              min={0}
              max={Number.MAX_VALUE}
              name={'sqmMax'}
              value={props.filter.sqmMax}
              onChange={handleFilterChange}
            />
          </label>
        </div>
      </form>,
      props.filterIsOpen && (
        <button
          ref={(_input) => (modalCloseButton = _input)}
          type={'button'}
          className={'form-close-button'}
          onClick={props.toggleFilter}
        >
          &times;
        </button>
      ),
    ];
  }

  return [
    <MediaQuery minDeviceWidth={1224}>
      {props.filterIsOpen && formContent()}
      {props.filterIsOpen && (
        <button
          type="button"
          onClick={() => {
            window.open('https://www.reichert-immobilien.at/immobilien/', '_blank');
          }}
        >
          {props.locationNumber} Treffer! Immobilien jetzt anzeigen oder in der
          Karte auswählen
        </button>
      )}
    </MediaQuery>,
    <MediaQuery maxDeviceWidth={1223}>
      <div>
        <Modal
          style={customStyles}
          isOpen={props.filterIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={props.toggleFilter}
        >
          {formContent()}
        </Modal>
      </div>
    </MediaQuery>,
  ];
}

export default React.memo(MapFilter);
